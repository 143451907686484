import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import Gap from 'bloko/blocks/gap';

import LightPage from 'src/app/layouts/LightPage';
import MagritteDefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout from 'src/app/layouts/PageLayout';
import CombinedLoginCards from 'src/components/AccountLogin/CombinedLoginCards/CombinedLoginCards';
import GridColumn from 'src/components/MagritteRedesignComponents/GridColumn';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import TinkoffBussinessErrorModal from 'src/components/SignInSignUpFlow/TinkoffBussinessErrorModal';
import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import useIsIframeView from 'src/hooks/useIsIframeView';
import { useSelector } from 'src/hooks/useSelector';

import AccountLoginLayout from 'src/pages/AccountLogin/AccountLoginLayout';

const TrlKeys = {
    title: 'account.login.header',
};

const AccountLoginPage: TranslatedComponent = ({ trls }) => {
    const isIframeView = useIsIframeView();
    const { errorMessage } = useSelector(({ errorPage }) => errorPage);
    const { isApplicantRenewedAuthExp } = useApplicantRenewedAuthExp();

    const loginCards = (
        <GridColumn xs="4" s="8" m="12" l="12">
            <div className="account-login-page">
                <GridColumn xs="4" s="6" m="6" l="4" container>
                    {errorMessage && <Gap bottom>{errorMessage}</Gap>}
                    <CombinedLoginCards />
                </GridColumn>
            </div>
        </GridColumn>
    );

    if (isIframeView) {
        return (
            <MagritteWrapper isEnabled>
                <LightPage>
                    {({ header, footer }) => (
                        <MagritteDefaultLayout>
                            <GridColumn xs="4" s="2" m="4" l="6">
                                <Gap sTop mTop lTop>
                                    {header}
                                </Gap>
                            </GridColumn>
                            <GridColumn xs="4" s="8" m="12" l="16" container>
                                <Gap xsTop>{loginCards}</Gap>
                            </GridColumn>
                            <GridColumn xs="4" s="8" m="12" l="16" container>
                                {footer}
                            </GridColumn>
                        </MagritteDefaultLayout>
                    )}
                </LightPage>
            </MagritteWrapper>
        );
    }

    return (
        <MagritteWrapper isEnabled>
            <PageLayout
                title={trls[TrlKeys.title]}
                layout={isApplicantRenewedAuthExp ? AccountLoginLayout : MagritteDefaultLayout}
            >
                {loginCards}
                <TinkoffBussinessErrorModal />
            </PageLayout>
        </MagritteWrapper>
    );
};

export default translation(AccountLoginPage);
