import { PropsWithChildren, type FC } from 'react';
import classNames from 'classnames';

import { useSelector } from '@hh.ru/front-static-app';

import { useApplicantRenewedAuthExp } from 'src/hooks/useApplicantRenewedAuthExp';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

import AccountLoginLayoutCopyright from 'src/pages/AccountLogin/AccountLoginLayout/AccountLoginLayoutCopyright';

import styles from './styles.less';

const AccountLoginLayout: FC<PropsWithChildren> = ({ children }) => {
    const { isApplicantRenewedAuthExpD } = useApplicantRenewedAuthExp();
    const accountType = useSelector((state) => state.accountLogin.accountType) ?? 'APPLICANT';
    const isZp = useIsZarplataPlatform();

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.wrapperWithHeader]: isApplicantRenewedAuthExpD,
                [styles.wrapperApplicant]: !isZp && accountType === 'APPLICANT',
                [styles.wrapperEmployer]: !isZp && accountType === 'EMPLOYER',
            })}
        >
            <div className={styles.content}>
                {children}
                <div className={styles.copyright}>
                    <AccountLoginLayoutCopyright />
                </div>
            </div>
        </div>
    );
};

export default AccountLoginLayout;
