import React from 'react';
import { Merge } from 'type-fest';

import { LightPageProps } from 'src/app/layouts/BlokoLayouts/LightPageLayout';
import PageLayout, { LightPageLayout } from 'src/app/layouts/PageLayout';
import { PageLayoutProps } from 'src/app/layouts/PageLayout.types';

const LightPage: React.FC<Merge<PageLayoutProps, LightPageProps>> = (layoutProps) => (
    <PageLayout layout={LightPageLayout} {...layoutProps} />
);

export default LightPage;
