import { VSpacing } from '@hh.ru/magritte-ui';
import { ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Button from 'src/components/MagritteRedesignComponents/Button';
import Modal from 'src/components/MagritteRedesignComponents/Modal';
import Text from 'src/components/MagritteRedesignComponents/Text';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

import styles from './tinfoff-business-error-modal.less';

const TrlKeys = {
    title: 'tinkoffBusiness.modal.title',
    reasons: 'tinkoffBusiness.modal.reasons',
    reason1: 'tinkoffBusiness.modal.reason.1',
    reason2: 'tinkoffBusiness.modal.reason.2',
    tryAgain: 'tinkoffBusiness.modal.tryAgain',
    ok: 'tinkoffBusiness.modal.ok',
};

const TinkoffBussinessErrorModal: TranslatedComponent = ({ trls }) => {
    const isError = useSelector((state) => state.isTinkoffBusinessError);
    const [isVisible, toggleVisible] = useToggleState(isError);
    const isMagritte = useMagritte();

    return (
        <Modal
            visible={isVisible}
            onClose={toggleVisible}
            title={trls[TrlKeys.title]}
            content={
                <div className={isMagritte ? '' : styles.content}>
                    <Text typography="label-1-regular">
                        {trls[TrlKeys.reasons]}
                        <VSpacing default={8} />
                        <ul className={styles.list}>
                            <li>{trls[TrlKeys.reason1]}</li>
                            <li>{trls[TrlKeys.reason2]}</li>
                        </ul>
                        <VSpacing default={8} />
                        {trls[TrlKeys.tryAgain]}
                    </Text>
                </div>
            }
            actions={
                <Button
                    size="medium"
                    style="accent"
                    mode="primary"
                    onClick={toggleVisible}
                    blokoComponentProps={{
                        kind: ButtonKind.Primary,
                    }}
                >
                    {trls[TrlKeys.ok]}
                </Button>
            }
        />
    );
};

export default translation(TinkoffBussinessErrorModal);
